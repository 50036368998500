<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Config chấm điểm rủi ro</h6>
        </template>
        <div>
          <h6 class="mb-0">Thiết lập điểm đánh giá</h6>
          <b-row class="custom-border ml-5 mt-2">
            <b-col v-for="(item, index) in (params.scores.length ? params.scores : [getDefaultScore()])" :key="index"
                   class="mt-3" md="12">
              <b-row>
                <b-col class="mt-3" md="1">
                  <b-form-group label="Số điểm *">
                    <b-form-input
                        v-model.number="item.point"
                        type="number"
                        min="0"
                        max="10"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="mt-3" md="3">
                  <b-form-group label="Mô tả *">
                    <b-form-select
                        v-model="item.description"
                        :options="params.options.descriptions"
                        value-field="value"
                        text-field="text"
                    ></b-form-select>

                  </b-form-group>
                </b-col>
                <button v-if="index === 0" @click="addConfig" type="button" class="text-primary">
                  <i class="fa fas fa-plus-circle blue ml-4 text-primary" style="margin-top: 20px;"
                     aria-hidden="true"></i>
                </button>
                <button v-if="index >= 1" @click="removeEntry(index, params.scores)" class="text-danger"
                        type="button">
                  <i class="fa fa-trash text-danger ml-2" style="margin-top: 20px;" aria-hidden="true"></i>
                </button>
                <button v-if="index >= 1" @click="addConfig" type="button" class="text-primary">
                  <i class="fa fas fa-plus-circle blue ml-4 text-primary" style="margin-top: 20px;"
                     aria-hidden="true"></i>
                </button>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <b-button class="mt-3" variant="primary" @click="checkRiskPoint" :disabled="!isFormValidConfig"
              >Tiếp tục
              </b-button>
            </b-col>
          </b-row>
        </div>

        <div>
          <h6 class="mb-0">Tiêu chí đánh giá</h6>
          <template>
            <div class="accordion mt-2" role="tablist">
              <b-card no-body class="mb-1">
                <b-button block v-b-toggle.accordion-1 variant="secondary" class="text-left font-weight-bold">Độ tuổi
                </b-button>
                <b-collapse id="accordion-1" accordion="my-accordion" v-model="isAccordionOpen" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <b-col
                          v-for="(age, index) in (params.riskCriteria.length  ? params.riskCriteria : [getDefaultCriteria1()])"
                          :key="index">
                        <b-row v-if="age.type === 'AGE'">
                          <b-col class="mt-3" md="1">
                            <b-form-group label="Từ (tuổi)">
                              <b-form-input v-model="age.value.age_from" type="number" min="0"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col class="mt-3" md="1">
                            <b-form-group label="Đến (tuổi)">
                              <b-form-input v-model="age.value.age_to" type="number" min="1"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col class="mt-3" md="3">
                            <b-form-group label="Mô tả *">
                              <b-form-input v-model="age.description"
                                            type="text"
                                            placeholder="Mô tả"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col v-if="params.scores.some(item => item.description === 'Thấp')" class="mt-5 ml-4"
                                 md="2">
                            <b-form-group label="Thấp">
                              <b-form-radio
                                  v-model="age.point"
                                  :value="getRadioValue('Thấp')"
                                  class="ml-4"
                                  size="lg"
                              ></b-form-radio>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Trung Bình')" class="mt-5"
                                 md="2">
                            <b-form-group label="Trung Bình">
                              <b-form-radio
                                  v-model="age.point"
                                  class="ml-4"
                                  :value="getRadioValue('Trung Bình')"
                                  size="lg"
                              ></b-form-radio>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Cao')" class="mt-5" md="2">
                            <b-form-group label="Cao">
                              <b-form-radio
                                  v-model="age.point"
                                  class="ml-4"
                                  :value="getRadioValue('Cao')"
                                  size="lg"
                              ></b-form-radio>
                            </b-form-group>
                          </b-col>
                          <button v-if="index === 0" @click="addConfigAge" type="button" class="text-primary">
                            <i class="fa fas fa-plus-circle blue ml-4 text-primary" style="margin-top: 20px;"
                               aria-hidden="true"></i>
                          </button>
                          <button v-if="index >= 1" @click="removeEntry(index, params.riskCriteria)" type="button"
                                  class="text-danger">
                            <i class="fa fa-trash text-danger ml-2" style="margin-top: 20px;" aria-hidden="true"></i>
                          </button>
                          <button v-if="index >= 1" @click="addConfigAge" type="button" class="text-primary">
                            <i class="fa fas fa-plus-circle blue ml-4 text-primary" style="margin-top: 20px;"
                               aria-hidden="true"></i>
                          </button>
                        </b-row>
                      </b-col>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-button block v-b-toggle.accordion-2 variant="secondary" class="text-left font-weight-bold">Địa chỉ
                  thường trú
                </b-button>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>

                      <b-col
                          v-for="(address, index1) in (params.riskCriteria.length  ? params.riskCriteria : [getDefaultCriteria2()])"
                          :key="index1">
                        <b-row
                            v-if="address.type === 'PROVINCE' ">
                          <b-col class="mt-3" md="2">
                            <b-form-group label="Chọn tỉnh/thành phố">
                              <b-form-select
                                  v-model="address.value.province"
                                  :options="params.options.provinces"
                                  value-field="value"
                                  text-field="text"
                              ></b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col class="mt-3" md="3">
                            <b-form-group label="Mô tả *">
                              <b-form-input v-model="address.description" type="text"
                                            placeholder="Mô tả"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Thấp')" class="mt-5 ml-4"
                                 md="2">
                            <b-form-group label="Thấp">
                              <b-form-radio
                                  v-model="address.point"
                                  :value="getRadioValue('Thấp')"
                                  class="ml-4"
                                  size="lg"
                              ></b-form-radio>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Trung Bình')" class="mt-5"
                                 md="2">
                            <b-form-group label="Trung Bình">
                              <b-form-radio
                                  v-model="address.point"
                                  :value="getRadioValue('Trung Bình')"
                                  class="ml-4"
                                  size="lg"
                              ></b-form-radio>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Cao')" class="mt-5" md="2">
                            <b-form-group label="Cao">
                              <b-form-radio
                                  v-model="address.point"
                                  :value="getRadioValue('Cao')"
                                  class="ml-4"
                                  size="lg"
                              ></b-form-radio>
                            </b-form-group>
                          </b-col>
                          <button v-if="index1 === 1" @click="addConfigAddress" type="button" class="text-primary">
                            <i class="fa fas fa-plus-circle blue ml-4 text-primary" style="margin-top: 20px;"
                               aria-hidden="true"></i>
                          </button>
                          <button v-if="index1 > 1" @click="removeEntry(index1, params.riskCriteria)" type="button"
                                  class="text-danger">
                            <i class="fa fa-trash text-danger ml-2" style="margin-top: 20px;" aria-hidden="true"></i>
                          </button>
                          <button v-if="index1 > 1" @click="addConfigAddress" type="button" class="text-primary">
                            <i class="fa fas fa-plus-circle blue ml-4 text-primary" style="margin-top: 20px;"
                               aria-hidden="true"></i>
                          </button>
                        </b-row>
                      </b-col>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-button block v-b-toggle.accordion-3 variant="secondary" class="text-left font-weight-bold">Quốc
                  tịch
                </b-button>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>

                      <b-col
                          v-for="(nation, index) in (params.riskCriteria.length  ? params.riskCriteria : [getDefaultCriteria3()])"
                          :key="index">
                        <b-row v-if="nation.type === 'NATIONALITY' && nation.value.nationality === 'Việt Nam'  ">
                          <b-col class="mt-3" md="2">
                            <b-form-group label="Quốc tịch">
                              <b-form-input type="text" value="Việt Nam"
                                            readonly></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col class="mt-3" md="3">
                            <b-form-group label="Mô tả *">
                              <b-form-input v-model="nation.description" type="text"
                                            placeholder="Mô tả"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Thấp')" class="mt-5 ml-4"
                                 md="2">
                            <b-form-group label="Thấp">
                              <b-form-radio
                                  v-model="nation.point"

                                  :value="getRadioValue('Thấp')" class="ml-4"
                                  size="lg"></b-form-radio>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Trung Bình')" class="mt-5"
                                 md="2">
                            <b-form-group label="Trung Bình">
                              <b-form-radio v-model="nation.point" :value="getRadioValue('Trung Bình')" class="ml-4"
                                            size="lg"></b-form-radio>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Cao')" class="mt-5" md="2">
                            <b-form-group label="Cao">
                              <b-form-radio v-model="nation.point" :value="getRadioValue('Cao')" class="ml-4"
                                            size="lg"></b-form-radio>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>

                      <b-col
                          v-for="(otherNation, index) in (params.riskCriteria.length  ? params.riskCriteria : [getDefaultCriteria4()])"
                          :key="index">
                        <b-row
                            v-if="otherNation.type === 'NATIONALITY' && otherNation.value.nationality==='Khác' ">
                          <b-col class="mt-3" md="2">
                            <b-form-group label="Quốc tịch">
                              <b-form-input type="text" value="Khác"
                                            readonly></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col class="mt-3" md="3">
                            <b-form-group label="Mô tả *">
                              <b-form-input v-model="otherNation.description" type="text"
                                            placeholder="Mô tả"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Thấp')" class="mt-5 ml-4"
                                 md="2">
                            <b-form-group label="Thấp">
                              <b-form-radio v-model="otherNation.point" :value="getRadioValue('Thấp')" class="ml-4"
                                            size="lg"></b-form-radio>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Trung Bình')" class="mt-5"
                                 md="2">
                            <b-form-group label="Trung Bình">
                              <b-form-radio v-model="otherNation.point" :value="getRadioValue('Trung Bình')"
                                            class="ml-4"
                                            size="lg"></b-form-radio>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="params.scores.some(item => item.description === 'Cao')" class="mt-5" md="2">
                            <b-form-group label="Cao">
                              <b-form-radio v-model="otherNation.point" :value="getRadioValue('Cao')" class="ml-4"
                                            size="lg"></b-form-radio>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <b-row>
              <b-col class="text-right">
                <b-button class="mt-3" variant="primary" @click="checkRiskCriteria" :disabled="!isOverallValid">Tiếp
                  tục
                </b-button>
              </b-col>
            </b-row>
          </template>
        </div>

        <div>
          <h6 class="mb-0">Thiết lập thang điểm phân loại rủi ro</h6>
          <b-row class="custom-border ml-5 mt-2">
            <b-col
                v-for="(item, index) in (params.inputRiskScore.length ? params.inputRiskScore : params.defaultRiskScore)"
                :key="index" class="mt-3" md="12">
              <b-row>
                <b-col class="mt-3" md="1">
                  <b-form-group label="Từ (điểm)">
                    <b-form-input
                        v-model="item.point_from"
                        type="number"
                        :min="item.point_from"
                        :max="item.point_to"
                        @input="validatePoint(item)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="mt-3 ml-12" md="1">
                  <b-form-group label="Đến (điểm)">
                    <b-form-input
                        v-model="item.point_to"
                        type="number"
                        :min="item.point_from"
                        :max="item.point_to"
                        @input="validatePoint(item)"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="mt-3 ml-24" md="2">
                  <b-form-group label="Mức độ RR">
                    <b-form-select
                        v-model="item.risk_level"
                        :options="params.options.RRLevel"
                        value-field="value"
                        text-field="text"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="mt-3 ml-24" md="2">
                  <b-form-group label="Hạn mức ảnh hưởng">
                    <b-form-select
                        v-model="item.impact_level"
                        :options="params.options.influenceLimit"
                        value-field="value"
                        text-field="text"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <button v-if="index === 0" @click="addRiskScore" class="ml-4 text-primary">
                  <i class="fa fas fa-plus-circle text-primary " style="margin-top: 15px;" aria-hidden="true"></i>
                </button>
                <button v-if="index >= 1" @click="removeEntry(index, params.inputRiskScore)" type="button"
                        class="text-danger">
                  <i class="fa fa-trash text-danger ml-2" style="margin-top: 20px;" aria-hidden="true"></i>
                </button>
                <button v-if="index >= 1" @click="addRiskScore" class="ml-4 text-primary">
                  <i class="fa fas fa-plus-circle text-primary " style="margin-top: 15px;" aria-hidden="true"></i>
                </button>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right mt-3">
              <b-button
                  v-if="countData > 0"
                  variant="primary"
                  @click="updateRiskPoint"
              >Cập Nhật
              </b-button>
              <b-button
                  variant="primary"
                  @click="storeRiskPoint"
                  v-if="countData === 0"
              >Lưu
              </b-button
              >&nbsp;
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common.vue";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";

Vue.use(Notifications)
const CmsRepository = RepositoryFactory.get("cms");

export default {
  mixins: [Common],

  data() {
    return {
      selectedPointAge: null,
      params: {
        scores: [
          {
            point: null,
            description: null
          }
        ],
        riskCriteria: [
          {
            type: "AGE",
            value: {
              age_from: null,
              age_to: null,
            },
            point: null,
            description: null

          },
          {
            type: "'PROVINCE', ",
            value: {
              province: null,
            },
            point: null,
            description: null

          },
          {
            type: "'NATIONALITY', ",
            value: {
              nationality: null
            },
            point: null,
            description: null

          }
        ],


        inputRiskScore: [
          {
            point_from: null,
            point_to: null,
            risk_level: null,
            impact_level: null,
          }],
        options: {
          descriptions: [
            {value: 'Thấp', text: 'Thấp'},
            {value: 'Trung Bình', text: 'Trung Bình'},
            {value: 'Cao', text: 'Cao'}
          ],
          provinces:
              [
                {value: 'Hà Nội', text: 'Hà Nội',},
                {value: 'Việt Nam', text: 'Việt Nam'},
                {value: 'Ngoài lãnh thổ Việt Nam', text: 'Ngoài lãnh thổ Việt Nam'},
              ],
          RRLevel:
              [
                {value: 'LOW', text: 'Thấp'},
                {value: 'MEDIUM', text: 'Trung Bình'},
                {value: 'HIGH', text: 'Cao'}
              ],
          influenceLimit:
              [
                {value: '1', text: 'Giữ nguyên hạn mức'},
                {value: '2', text: 'Giảm 30% hạn mức'}
              ],
        },
        defaultRiskScore: [
          {
            point_from: null,
            point_to: null,
            risk_level: null,
            impact_level: null,
          }],
      },
      countData: 0,

      isAccordionOpen: false,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Chấm điểm rủi ro", route: "tools/risk/scoring"},
    ]);
    this.getRiskPoint();
  },

  computed: {
    isFormValidConfig() {
      return this.params.scores.every(score => {
        return score.point !== null && score.point !== '' && score.description !== null;

      });
    },
    isOverallValid() {
      return this.params.scores.every(score => {
        return score.point !== null && score.point !== '' && score.description !== null;
      });
    },

  },

  methods: {
    getRadioValue(description) {
      const values = this.params.scores.reduce((acc, score) => {
        if (score.description === 'Thấp') acc['Thấp'] = score.point;
        if (score.description === 'Trung Bình') acc['Trung Bình'] = score.point;
        if (score.description === 'Cao') acc['Cao'] = score.point;
        return acc;
      }, {});
      return values[description] || 0;
    },
    getRiskPoint() {
      CmsRepository.getRiskPoint()
          .then((response) => {
            if (response.data.error_code) {
              this.notifyAlert("warn", response.data.message);
              this.$bus.$emit("show-loading", false);
            } else {
              const data = response.data.data;

              this.params.scores = data.risk_points.length ? data.risk_points : [this.getDefaultScore()];
              this.params.riskCriteria = data.risk_criteria.length ? data.risk_criteria : [this.getDefaultCriteria1(), this.getDefaultCriteria2(),
                this.getDefaultCriteria3(), this.getDefaultCriteria4()];
              this.params.inputRiskScore = data.risk_levels ;
              if(data.risk_points.length > 0 && data.risk_criteria.length > 0 && data.risk_levels.length > 0) {
                this.countData = 1;
              }
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
    },

    addConfig() {
      this.params.scores.push({point: null, description: null});
    }
    ,
    addConfigAge() {
      const defaultAgeConfig = {
        type: 'AGE',
        value: {
          age_from: null,
          age_to: null,
          province: null,
          nationality: null
        },
        point: null,
        description: null,
      };

      this.params.riskCriteria.push(defaultAgeConfig);
    }
    ,

    addConfigAddress() {
      const defaultAddressConfig = {
        type: 'PROVINCE',
        value: {
          age_from: null,
          age_to: null,
          province: null,
          nationality: null
        },
        point: null,
        description: null,
      };

      this.params.riskCriteria.push(defaultAddressConfig);
    }
    ,
    addRiskScore() {
      this.params.inputRiskScore.push({
        point_from: null,
        point_to: null,
        risk_level: null,
        impact_level: null,
      });

    }
    ,
    removeEntry(index, param) {
      param.splice(index, 1);
    }
    ,
    checkRiskPoint() {
      if (!this.continueEvaluate()) {
        return
      }
      let params = {
        point: this.params.scores.map(score => score.point),
        description: this.params.scores.map(score => score.description),
      };


      CmsRepository.checkRiskPoint(params)
          .then((response) => {
            if (response.data.error_code) {
              this.notifyAlert("warn", response.data.message);
              return false;
            } else {
              this.notifyAlert("success", "Thiết lập điểm đánh giá thành công");
              this.isAccordionOpen = true;
            }
          })
          .catch((e) => {
            alert(e.message)
            alert("Có lỗi xảy ra");
          });
    },
    checkRiskCriteria() {
      let params = this.convertRiskCriteriaForm();
      CmsRepository.checkRiskCriteria(params)
          .then((response) => {
            if (response.data.error_code) {
              this.notifyAlert("warn", response.data.message);
              return false;
            } else {
              this.notifyAlert("success", "Thiết lập Tiêu chí đánh giá thành công ");

              this.$set(this.params, 'inputRiskScore', [{
                point_from: response.data.data.point_min,
                point_to: response.data.data.point_max,
                risk_level: null,
                impact_level: null
              }]);
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
    },
    storeRiskPoint() {
      if(!this.validatePoint){
        return
      }
      let params = this.convertUpdateForm();
      CmsRepository.storeRiskPoint(params)
          .then((response) => {
            if (response.data.error_code) {
              this.notifyAlert("warn", response.data.message);
              return false;
            } else {
              this.notifyAlert("success", "Config thành công");
              location.reload();
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
    },

    updateRiskPoint() {
      if(!this.validatePoint){
        return
      }
      let params = this.convertUpdateForm();
      CmsRepository.updateRiskPoint(params)
          .then((response) => {
            if (response.data.error_code) {
              this.notifyAlert("warn", response.data.message);
              return false;
            } else {
              this.notifyAlert("success", "Update Config thành công");
              location.reload();
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
    },
    convertUpdateForm() {
      let params = {
        risk_points: [],
        risk_criteria: [],
        risk_levels: []
      };

      this.params.scores.forEach(score => {
        params.risk_points.push({
          point: score.point,
          description: score.description
        });
      });

      this.params.riskCriteria.forEach(criteria => {
        let type = criteria.type;
        let value = {};
        let description = criteria.description || "";
        let point = null;

        const scoreObj = this.params.scores.find(score => score.point === criteria.point);
        point = scoreObj ? scoreObj.point : null;

        switch (type) {
          case 'AGE':
            value = {
              age_from: criteria.value.age_from,
              age_to: criteria.value.age_to
            };
            break;
          case 'PROVINCE':
            value = {
              province: criteria.value.province
            };
            break;
          case 'NATIONALITY':
            value = {
              nationality: criteria.value.nationality
            };
            break;
          default:
            break;
        }

        params.risk_criteria.push({
          type: type,
          value: value,
          point: point,
          description: description
        });
      });

      this.params.inputRiskScore.forEach(riskScore => {
        params.risk_levels.push({
          point_from: parseInt(riskScore.point_from),
          point_to: parseInt(riskScore.point_to),
          risk_level: riskScore.risk_level,
          impact_level: riskScore.impact_level
        });
      });

      return params;

    },
    convertRiskCriteriaForm() {
      let params = {
        type: [],
        value: [],
        point: [],
        description: []
      };

      for (let item of this.params.riskCriteria) {
        let type = item.type
        let value = null;
        let description = item.description || "";
        let point = null;

        switch (type) {
          case 'AGE':
            value = {
              age_from: item.value.age_from,
              age_to: item.value.age_to
            };
            params.type.push("AGE");
            params.value.push(value);
            break;
          case 'PROVINCE':
            value = {
              province: item.value.province
            };
            params.type.push("PROVINCE");
            params.value.push(value);
            break;
          case 'NATIONALITY':
            if (item.value.nationality === 'Việt Nam') {
              value = {
                nationality: 'Việt Nam'
              };
              params.type.push("NATIONALITY");
              params.value.push(value);
            } else if (item.value.nationality === 'Khác') {
              value = {
                nationality: 'Khác'
              };
              params.type.push("NATIONALITY");
              params.value.push(value);
            }
            break;
        }
        const scoreObj = this.params.scores.find(score => score.point === item.point);
        point = scoreObj ? scoreObj.point : null;


        params.description.push(description);
        params.point.push(point);
      }

      return params;
    },

    validatePoint(item) {
      if (item.point_from < 0 || item.point_to < 0) {
        this.notifyAlert('warn', 'Điểm không thể nhỏ hơn 0.');

      }
      // if (item.point_from > item.point_to) {
      //   this.notifyAlert('warn', 'Điểm "Từ" không thể lớn hơn điểm "Đến".');
      // }
    },
    continueEvaluate() {
      let countError = 0
      this.params.scores.forEach((score) => {
        if (score.description === null) {
          this.notifyAlert("warn", `Mô tả điểm đánh giá không được để trống `);
          countError++;
        }
        if (score.score === null || score.score === "") {
          this.notifyAlert("warn", `Điểm đánh giá không được để trống `);
          countError++;
        }
        if (score.score < 0 || score.score > 10) {
          this.notifyAlert("warn", `Điểm đánh giá phải từ 0 đến 10 `);
          countError++;
        }
      });
      return countError <= 0;
    },

    getDefaultScore() {
      return {
        point: null,
        description: null
      };
    },
    getDefaultCriteria1() {
      return {
        type: "AGE",
        value: {
          age_from: null,
          age_to: null,
        },
        point: null,
        description: null

      }

    },
    getDefaultCriteria2() {
      return {
        type: "PROVINCE",
        value: {
          province: null,
        },
        point: null,
        description: null

      }

    },
    getDefaultCriteria3() {
      return {
        type: "NATIONALITY",
        value: {
          nationality: "Việt Nam"
        },
        point: null,
        description: null

      }

    },
    getDefaultCriteria4() {
      return {
        type: "NATIONALITY",
        value: {
          nationality: "Khác"
        },
        point: null,
        description: null

      }

    },



  }
  ,

}
;
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.custom-border {
  border: 1.5px solid;

}

</style>

